// SplashScreen.js
import React from 'react';
import './SplashScreen.css';

const SplashScreen = () => (
  <div className="splash-screen">
    <img src="/images/myDoldari.png" alt="Doldari Logo" className="splash-logo" />
  </div>
);

export default SplashScreen;
