import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'; 

// Firebase 프로젝트 설정
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, 
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID  
}; 

// const firebaseConfig = {
//     apiKey: "AIzaSyDoyHZ3X83fJW9n008FwvQ43dZWq9Dn6dI",
//     authDomain: "tgabyssweb.firebaseapp.com",
//     projectId: "tgabyssweb",
//     storageBucket: "tgabyssweb.appspot.com",
//     messagingSenderId: "424638202551",
//     appId: "1:424638202551:web:53307a6955d808b2794332",
//     measurementId: "G-EZGLSHBTPB"
// };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, signInWithPopup };