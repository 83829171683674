// File name : App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown'; // react-markdown 추가
import { signInWithPopup, signInWithRedirect } from 'firebase/auth';
import { auth, googleProvider} from './firebase';
import Intro from './Intro';
import Home from './Home'; // Home 컴포넌트 추가



import './styles-global.css';
import './App.css'; 


function App() {
  const [webpageData, setWebpageData] = useState([]);
  const [sloganText, setSloganText] = useState(''); // Slogan text 상태 추가 

  // const [menuItems, setMenuItems] = useState([]); 
  // const [currentIndex, setCurrentIndex] = useState(-1);
  // const [selectedItem, setSelectedItem] = useState(null);
  // const [showDetails, setShowDetails] = useState(false);
  // const [viewFile, setViewFile] = useState(false);
  // const [animationFinished, setAnimationFinished] = useState(false); 
  // const [isHovered, setIsHovered] = useState(false); 
  // const [isTouching, setIsTouching] = useState(false); 
  // const [isAnimating, setIsAnimating] = useState(false); 
  const [user, setUser] = useState(null); // 로그인된 사용자 상태 추가 
  const storedLanguage = localStorage.getItem('language'); // 저장된 language 읽기
  const [language, setLanguage] = useState(storedLanguage || 'EN'); // 기본값은 EN
  const [userIp, setUserIp] = useState('');


  let SERVER_ROOT = 'https://www.doldari.com:3001';

  const LOCAL_MODE = false;

  if (LOCAL_MODE) {
    SERVER_ROOT = 'http://localhost:3001';
  } 

  // 모바일 감지 함수
  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  // Google Provider에 매번 계정을 선택하도록 설정
  googleProvider.setCustomParameters({ prompt: 'select_account' });

 

  useEffect(() => {
      axios.get(`${SERVER_ROOT}/api/userip`) // Replace SERVER_ROOT with your server URL
          .then((response) => {
              console.log('Fetched IP:', response.data.ip); // Debug log
              setUserIp(response.data.ip); 
          })
          .catch((error) => {
              console.error('Error fetching IP address:', error);
          });
  }, []);


  // useEffect(() => {
  //   // Check for saved language preference in localStorage
  //   const savedLanguage = localStorage.getItem('language');
  //   if (savedLanguage) {
  //     setLanguage(savedLanguage);
  //   } else {
  //     // Geolocation API 호출로 기본 언어 설정
  //     axios
  //       .get(`${SERVER_ROOT}/api/check-country`)
  //       .then((response) => {
  //         const country = response.data.country; // 국가 정보 가져오기
  //         if (country === 'South Korea') {
  //           setLanguage('KR'); // 한국일 경우 언어를 KR로 설정
  //         } else {
  //           setLanguage('EN'); // 그 외는 EN
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching location data:', error);
  //       });
  //   }
  // }, []);

  useEffect(() => {
    console.log('Current user:', user);
  }, [user]);

  // Check localStorage for a user on initial load
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser); // Restore the user from localStorage if found
    }
  }, []);

  // Function to handle Google login
  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const loggedInUser = {
        uid: result.user.uid,
        displayName: result.user.displayName,
        email: result.user.email,
      };
      setUser(loggedInUser);
      localStorage.setItem('user', JSON.stringify(loggedInUser)); 
    } catch (error) {
      console.error('Google Login Error:', error);
    }
  };

  const handleNaverLogin = () => {
    alert('Naver login is currently under development.'); 
  };

  // Function to handle logout
  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      localStorage.removeItem('user'); // Remove user from localStorage on logout
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  // const handleLanguageToggle = () => {
  //   setLanguage((prevLanguage) => (prevLanguage === 'KR' ? 'EN' : 'KR'));
  // };

  const handleLanguageToggle = () => {
    setLanguage((prevLanguage) => {
      const newLanguage = prevLanguage === 'KR' ? 'EN' : 'KR';
      localStorage.setItem('language', newLanguage); // localStorage에 저장
      return newLanguage;
    });
  };

  

  const getValueByName = (dataArray, name) => {
    const record = dataArray.find(record => record.name === name);
    return record ? record.value : null; // name이 없을 경우 null 반환
  };
  
  useEffect(() => { 
    const tableName = language === 'EN' ? 'webpage_EN' : 'webpage';

    console.log(tableName);

    axios.get(`${SERVER_ROOT}/api/${tableName}`) 
      .then(response => {
        console.log(`Fetched ${tableName} data:`, response.data); // 데이터 출력
        setWebpageData(response.data);

        console.log(webpageData);

        // name이 'App.js_slogun'인 record 찾기
        const sloganRecord = response.data.find(item => item.name === 'App.js_slogun');
        if (sloganRecord) {
          setSloganText(sloganRecord.value); // 상태 업데이트
        } 
      })
      .catch(error => {
        console.error("Error fetching webpage data:", error);
      });
  }, [language]);


  

  return ( 
    <Router>
      <div className="App">
        <div className="topmenu">  
          <Link to="/">
            <img src="/resources/logo-doldari2.png" alt="Doldari" className="logo" />
          </Link>
          {/* <img src="resources/q2-2-removebg-preview.png" alt="TG Abyss Logo" class="logo"></img> */}

          {/* <div>Your IP Address: {userIp}</div>; */}

          <button onClick={handleLanguageToggle} className="language-button">
            {language === 'KR' ? 'KR' : 'EN'}
          </button> 

          {user ? (
            <>
            {/* <span>{user.displayName}</span> */}
            <button className='logout-button' onClick={handleLogout}> {language === 'KR' ? '로그아웃' : 'Logout'} </button>
          </>
          ) : (
            <div>
              <button onClick={handleGoogleLogin} className="googleLogin-button">
                {language === 'KR' ? '구글 로그인' : 'Google Login'}
              </button>
              {/* {language === 'KR' ? (
                <button onClick={handleNaverLogin} className="naverLogin-button">
                  네이버 로그인
                </button>
              ) : null} */}
            </div>
             
          )}  
              
        </div>
        {/* <div>
          <header className="App-header"> */}
            
        <div className="content">    
          <Routes> 
            <Route path="/" element={<Home language={language} user={user}/>} />
            <Route path="/intro" element={<Intro language={language} />} />
          </Routes> 
        </div>

        
          {/* </header>
          
        </div> */}
      </div>  
    </Router>  
  );
}

export default App;
